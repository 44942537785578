<template>
  <div v-if="!overlay">
    <div class="search bg_box-beige" style="z-index: 500">
      <div class="search__caption">
        <div class="search__caption__copy">
          <h1>Il Catalogo</h1>
          <div class="search__caption__copy__searchbox">
            <v-text-field
              class="ctlg"
              v-on:keyup="keyPress"
              dense
              v-model="searchText"
              placeholder="Cerca in tutto il Catalogo GFL"
              @click:append="
                page = 1
                research(0, 8)
              "
              rounded
              append-icon="search"></v-text-field>
            <a class="cta blue advanced" title="Ricerca Avanzata" style="font-size: 12pt" @click="showResAdv = !showResAdv" v-if="!showResAdv">
              <span>Ricerca Avanzata</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="search__advanced" v-show="showResAdv">
      <!-- pannello ricerca avanzata - va mostrato al click della CTA Ricerca Avanzata -->
      <div class="search__advanced__copy">
        <span class="panel-close">
          <span class="material-icons" @click="showResAdv = false">close</span>
        </span>
        <div class="search__advanced__copy__item">
          <div class="advanced__row">
            <label class="advanced__row__title">Data</label>
            <hr class="border-blue" />
          </div>
          <v-flex xs12 sm6 md4 class="advanced__row">
            <label style="margin-top: -25px">Da</label>
            <v-text-field label="Anno" outlined type="number" style v-model="dateFrom" :rules="[rules.min, rules.max]"></v-text-field>
            <label style="margin-top: -25px; margin-left: 10px">A</label>
            <v-text-field label="Anno" outlined type="number" v-model="dateTo" :rules="[rules.max]"></v-text-field>
          </v-flex>
        </div>
        <div class="search__advanced__copy__item">
          <div class="advanced__row">
            <label class="advanced__row__title">Autore</label>
            <hr class="border-blue" />
          </div>
          <v-flex xs12 sm6 md4 class="advanced__row">
            <input type="text" name v-model="author" placeholder="Autore" />
          </v-flex>
        </div>
        <div class="search__advanced__copy__item">
          <div class="advanced__row">
            <label class="advanced__row__title">Volume</label>
            <hr class="border-blue" />
          </div>
          <div class="flex advanced__row xs12 sm6 md4">
            <input class="volume__code" type="text" name placeholder="Codice volume" v-model="volume" />
            <input class="isbn__code" type="text" name placeholder="Codice ISBN" v-model="isbn" />
          </div>
          <div class="search__advanced__copy__actions" v-if="showResAdv">
            <!-- va mostrato quando i campi di ricerca vengono popolati -->
            <a
              href="#"
              class="cta orange"
              title="Cerca"
              @click="
                page = 1
                research(0, 8)
              ">
              <span>Cerca</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <v-container>
        <div class="intro">
          <h2>Di ultima pubblicazione</h2>
        </div>
        <div class="filter">
          <v-combobox
            class="ctlg"
            v-model="materiaSelected"
            :items="materie"
            light
            item-text="nome_interesse"
            dense
            placeholder="Materia"
            rounded
            clearable
            offset-y
            @change="setMateria()"
            :menu-props="{ auto: true, maxHeight: '250', minWidth: '350' }"></v-combobox>
        </div>
        <div v-if="catalogue">
          <v-container style="margin-top: -60px; background: white" class="hidden-sm-and-down">
            <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
            <div class="grid clearfix">
              <div class="grid__item" v-for="item in catalogue" :key="item" @click="detail(item)">
                <a class="grid__item__caption">
                  <div class="grid__item__caption__image">
                    <v-img v-if="item.provvedimento.campiCalcolati.cover_url" height="306" width="217" :src="getImage(item)"></v-img>

                    <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
                  </div>
                  <div class="grid__item__caption__text">
                    <h4>{{ item.provvedimento.titolo }}</h4>
                    <p>
                      <span v-if="item.provvedimento.collana">{{ item.provvedimento.collana.descrizione }}</span>
                      <br />
                      <span style="font-weight: bold" v-if="item.autori[0]">{{ item.autori[0].nome.substring(0, 1) }}. {{ item.autori[0].cognome }}</span>
                      <span style="font-weight: bold" v-if="item.autori[1]">{{ item.autori[1].nome.substring(0, 1) }}. {{ item.autori[1].cognome }}</span>
                    </p>
                  </div>
                </a>
              </div>
              <div class="grid__actions">
                <a class="cta blue big" title="Carica altri risultati" @click="moreResults()">Più risultati</a>
              </div>
            </div>
          </v-container>
        </div>

        <v-card elevation="24" max-width="444" class="mx-auto hidden-md-and-up" style="height: 306px; !important; width: 217px; !important">
          <v-carousel v-model="model" hide-delimiters @change="carousel(item)">
            <v-carousel-item v-for="item in catalogue" :key="item" @click="detail(item)">
              <v-img v-if="item.provvedimento.campiCalcolati.cover_url" height="306" width="217" :src="getImage(item)"></v-img>

              <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
        <v-row justify="space-around" v-if="currentItem" style="margin-top: 20px" class="mx-auto hidden-md-and-up">
          <div class="grid__item__caption__text">
            <h4>{{ currentItem.provvedimento.titolo }}</h4>
            <p>
              <span v-if="currentItem.provvedimento.collana">{{ currentItem.provvedimento.collana.descrizione }}</span>
              <br />
              <span style="font-weight: bold" v-if="currentItem.autori[0]">{{ currentItem.autori[0].nome.substring(0, 1) }}. {{ currentItem.autori[0].cognome }}</span>
              <span style="font-weight: bold" v-if="currentItem.autori[1]">{{ currentItem.autori[1].nome.substring(0, 1) }}. {{ currentItem.autori[1].cognome }}</span>
            </p>
          </div>
        </v-row>
        <div class="grid__actions mx-auto hidden-md-and-up">
          <a class="cta blue big" title="Carica altri risultati" @click="moreResults()">Più risultati</a>
        </div>
      </v-container>
    </div>
  </div>
  <h1 v-else style="text-align: center; margin: 0 auto">
    <v-progress-circular style="margin-top: 20px" indeterminate color="primary"></v-progress-circular>
  </h1>
</template>
<style>
  .ctlg .v-input__slot {
    border: solid 1px #cdcdcd;
    height: 40px !important;
    font-size: 10pt;
  }
  .v-text-field__slot {
    /*margin-top: 4px;*/
  }

  .v-input__icon.v-input__icon--append {
    margin-top: 3px;
  }
  .v-card {
    transition: opacity 0.3s ease-in-out;
  }
  .v-menu__content {
    /*box-shadow: none !important;*/
  }
  .v-card:not(.on-hover) {
    opacity: 0.8;
  }
  .order.v-list.v-sheet.v-sheet--tile.theme--light.v-list--dense {
    margin-top: 40px !important;
    margin-left: 125px !important;
    overflow-y: hidden !important;
  }
  .titl {
    cursor: pointer;
  }
  .titl:hover {
    text-decoration: underline;
  }

  .v-input__slot input::-webkit-outer-spin-button,
  .v-input__slot input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .v-input__slot input[type='number'] {
    border: none;
  }

  i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
    cursor: pointer !important;
  }
</style>
<script>
  import NoContentViewVue from '../components/catalogue/NoContentView.vue'
  import axios from 'axios'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      NoContentView: NoContentViewVue
    },
    data: (vm) => ({
      page: 1,
      totalPages: 1,
      loaded: false,
      dateFrom: null,
      dateTo: null,
      author: '',
      user: null,
      catalogue: null,
      materie: [],
      materiaSelected: null,
      totalResults: 0,
      searchText: null,
      chapterText: '',
      errorMin: false,
      errorMax: false,
      rules: {
        min: (value) => vm.check(value, true),
        max: (value) => vm.check(value, false)
      },
      list: [],
      showResAdv: false,
      nlp: null,
      currentItem: null,
      volume: null,
      isbn: null,
      currentOrder: {
        id: 1,
        name: 'Più pertinente',
        sort: {
          name: '_score',
          order: 'desc'
        }
      },
      orders: [
        {
          id: 1,
          name: 'Più pertinente',
          sort: {
            name: '_score',
            order: 'desc'
          }
        },
        {
          id: 2,
          name: 'Più recente',
          sort: {
            name: 'provvedimento.data',
            order: 'desc'
          }
        }
      ]
    }),
    watch: {
      page(val) {
        this.research((val - 1) * 8, 8)
      }
    },
    mounted() {
      //this.$gtm.trackView('pageview', this.$route.path);

      var d = new Date()
      var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()

      if (this.authorization.jwt) {
        this.user = this.authorization
      } else if (this.settings.configuration) {
      }

      this.$gtm.trackEvent({
        event: 'sezione',
        azione: 'open',
        descrizione: 'catalogo',
        proprieta: 1,
        utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
        crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
        stato: ''
      })

      if (this.settings.catalogo && this.settings.catalogo.length) {
        this.materie = this.settings.catalogo.materie
        /* this.materiaSelected = this.settings.catalogo.materiaSelected
        ? this.settings.catalogo.materiaSelected[0]
        : null;*/

        //this.searchText = this.settings.catalogo.search.nlp.text;
        this.materiaSelected = null
        this.settings.catalogo.materiaSelected = null
        this.list = this.settings.catalogo.search.es.list
        this.totalResults = this.settings.catalogo.search.es.total
        this.nlp = this.settings.catalogo.search.nlp
        this.catalogue = this.settings.catalogo.catalogue
        this.totalPages = parseInt(Math.round(this.totalResults / 8)) > 0 ? parseInt(Math.ceil(this.totalResults / 8)) : 1
        //this.chapterText = this.settings.catalogo.search.nlp.textOrig;
        if (this.catalogue) {
        } else {
          this.REQUEST(this.settings.configuration.apiURL + '/last/' + this.settings.homepage.from + '/' + this.settings.homepage.last, null, 'POST', 'list', null)
        }
      } else {
        if (this.settings.configuration) {
          var header = {
            Authorization: localStorage.token_biblio_vol_jwt
          }

          if (localStorage.materie) {
            //controllo la data di scadenza per il refresh, settata a 20 min
            if (localStorage.dateSavedMaterie) {
              var now = Date.parse(new Date()) / 1000
              var exp = parseInt(localStorage.dateSavedMaterie) + parseInt(this.settings.configuration.timeoutReloadMaterie)
              if (now >= exp) {
                this.loadMaterie(header)
              } else {
                this.materie = JSON.parse(localStorage.materie)
              }
            } else {
              localStorage.materie = []
              this.loadMaterie(header)
            }
          } else {
            this.loadMaterie(header)
          }

          /*setInterval(() => {
          self.check(self.dateFrom, true);
          self.check(self.dateTo, false);
        }, 300);*/

          this.REQUEST(this.settings.configuration.apiURL + '/last/' + this.settings.homepage.from + '/' + this.settings.homepage.last, null, 'POST', 'list', null)
        }
      }
    },
    created() {},
    methods: {
      async REQUEST(call, header, method, type, body) {
        var self = this
        this.loading = true
        if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'list':
                  self.catalogue = response.data.list
                  if (self.settings.catalogo) self.settings.catalogo.catalogue = self.catalogue

                  self.currentItem = self.catalogue[0]
                  break
                case 'search':
                  self.list = response.data.es.list
                  self.nlp = response.data.nlp
                  self.totalResults = response.data.es.total
                  self.totalPages = parseInt(Math.round(self.totalResults / 8)) >= 1 ? parseInt(Math.ceil(self.totalResults / 8)) : 1
                  //self.chapterText = response.data.nlp.textOrig;
                  self.loaded = true
                  self.settings.catalogo = {
                    search: response.data,
                    materiaSelected: self.materiaSelected,
                    materie: self.materie,
                    fromDetail: false,
                    filters: {
                      dateFrom: self.dateFrom,
                      dateTo: self.dateTo,
                      author: self.author,
                      fromUnlimited: false,
                      fromVolumes: false
                    }
                  }

                  if (self.showResAdv) {
                    self.$router.push({
                      path: 'results',
                      query: { more: true }
                    })
                  } else {
                    self.$router.push({
                      path: 'results'
                    })
                  }
                  self.showResAdv = false
              }
            })
            .catch(function (error) {
              //localStorage.token_biblio_vol = null;
              //localStorage.token_biblio_vol_jwt = self.settings.free.jwt;
              //self.token = self.settings.free.jwt;
              //self.settings.freeToken = true;
            })
        } else {
          await axios
            .get(call, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'materie':
                  self.materie = response.data.materie
                  self.materie.unshift({
                    nome_interesse: 'Tutto il catalogo'.toUpperCase()
                  })
                  localStorage.materie = JSON.stringify(self.materie)
                  localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000
                  break
              }
            })
            .catch(function (error) {
              self.loaded = true
            })
        }
      },
      check(val, from) {
        //value <= vm.dateTo || 'Errore nelle date'
        if (from) {
          if (parseInt(val) > parseInt(this.dateTo)) {
            return 'Errore nelle date.'
          }
        } else {
          if (val != '' && parseInt(val) < parseInt(this.dateFrom)) return 'Errore nelle date.'
        }
        return true
      },
      loadMaterie(header) {
        this.REQUEST(this.settings.configuration.apiURL + '/materie', header, 'GET', 'materie', null)
      },
      moreResults() {
        this.settings.catalogo = null
        this.$router.push({
          path: 'results',
          query: { more: true, fromButton: true }
        })
      },
      carousel(item) {
        this.currentItem = this.catalogue[this.model]
      },
      setMateria() {
        this.page = 1
        this.research((this.page - 1) * 8, 8)
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      setOrder(item) {
        this.currentOrder = item
        this.research((this.page - 1) * 8, 8)
      },
      verifyUserConditions(item, type) {
        if (type == 'unlimited') {
          if (this.settings.freeToken) {
            return false
          } else {
            if (item._source.provvedimento.utente.unlimited == false && item._source.provvedimento.is_unlimited) {
              return true
            } else return false
          }
        } else if (type == 'abbonato') {
          if (this.settings.freeToken) {
            return false
          } else {
            if (item._source.provvedimento.utente.volume) {
              return true
            } else return false
          }
        }
      },
      detail(item) {
        this.$router.push({
          path: '/details',
          query: { cod_vol: item.provvedimento.codVol }
        })
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.page = 1
          this.research((this.page - 1) * 8, 8)
        }
      },
      getImage(item) {
        //BVA-93
        let prefix = this.settings.copertina + '/pdf/'
        let uri = item.provvedimento.idDocMaster.toString().substring(item.provvedimento.idDocMaster.toString().length - 3, item.provvedimento.idDocMaster.toString().length)
        let fix = '/' + item.provvedimento.campiCalcolati.cover_url
        let final = prefix + uri + fix.replace('//', '/') + '?width=217&fit=outside'
        return final.replace(/([^:]\/)\/+/g, '$1')
      },
      research(from, to) {
        var body = {}
        var stop = false
        if (true) {
          if (this.materiaSelected && this.materiaSelected.nome_interesse.toUpperCase() != 'TUTTO IL CATALOGO') {
            body = {
              filters: [{ name: 'materia', value: [this.materiaSelected.interesse_id] }],
              sort: this.currentOrder.sort
            }
          } else {
            body = {
              filters: [],
              sort: this.currentOrder.sort
            }
          }
          if (this.searchText) {
            body.text = this.searchText
          }
          if (!this.searchText && this.showResAdv && this.author) {
            body.text = this.author
          }

          if (!this.searchText) {
            //PBV-277
            body.sort = {
              name: 'provvedimento.data',
              order: 'desc'
            }
          } else body.sort = { name: '_score', order: 'desc' }

          var flagAuthor = false
          if (this.showResAdv) {
            var filterDate = {}
            //verifiche
            var dateFrom = this.dateFrom ? this.dateFrom : 1930
            var dateTo = this.dateTo ? this.dateTo : new Date().getFullYear()
            if (parseInt(dateFrom) <= parseInt(dateTo) && parseInt(dateFrom) > 0 && parseInt(dateTo) > 0) {
              if (dateFrom != null || dateTo != null) {
                filterDate.name = 'range'
                var values = {
                  from: dateFrom ? dateFrom.toString() + '-01-01T00:00:00' : '1930-01-01T00:00:00',
                  to: dateTo ? dateTo.toString() + '-12-31T00:00:00' : '2100-01-01T00:00:00'
                }
                filterDate.value = values
                body.filters.push(filterDate)
              }
              var filterAuthor = {}
              if (this.author != '' && this.author != null) {
                filterAuthor.name = 'autore'
                var values = {
                  cognome: this.author
                }
                filterAuthor.value = values
                body.filters.push(filterAuthor)
                flagAuthor = true
              }
              var filterVolume = {}
              if (this.volume != '' && this.volume != null) {
                //gestione errore 9 caratteri
                if (this.volume.length < 9) {
                  alert('Il codice volume deve essere di 9 caratteri')
                }
                filterVolume.name = 'codVol'
                filterVolume.value = this.volume.toString()
                body.filters.push(filterVolume)
              }
              var filterISBN = {}
              if (this.isbn != '' && this.isbn != null) {
                filterISBN.name = 'isbn'
                filterISBN.value = this.isbn.toString()
                body.filters.push(filterISBN)
              }
            } else {
              if (this.dateFrom && this.dateTo) stop = true
            }
          }
          if (!stop) {
            this.settings.curentFilter = 'Tutto il Catalogo'
            var nlp = ''
            var header = {
              Authorization: localStorage.token_biblio_vol_jwt
            }
            this.REQUEST(this.settings.configuration.apiURL + '/search/volumi/' + from + '/' + to + nlp, header, 'POST', 'search', body)
          }
        }
      }
    }
  }
</script>
